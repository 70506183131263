<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active" aria-current="page">衍生設計</li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2 class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3">
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold">撲克牌-原民牌牌讚</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-2 g-2">
        <div class="col-lg-2" v-for="index in 55" :key="index">
          <div class="card border-0 rounded-0 loading">
            <div
              class="post_img_poker border d-flex justify-content-center align-items-center"
            >
              <a :href="getPhoto(index)" target="_blank">
                <img
                  :src="getPhoto(index)"
                  class="card-img-top rounded-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-12">
          <h3 class="pt-2 pb-2 fw-bold">
            <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>設計說明
          </h3>
          <p class="lh-lg font-1-2em">
            以《蕃人觀光日誌》中赴日參訪的四族臺灣原住民：魯凱族、鄒族、泰雅族和布農族製作成撲克牌的四組花色，並分別以這四族的原住民圖騰作為撲克牌數字的設計圖案，此外，更結合四族的文化特色繪製出年輕活力的原民生活插畫。
          </p>
          <h3 class="pt-2 pb-2 fw-bold">
            <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>設計團隊
          </h3>
          <p class="lh-lg font-1-2em">
            新北市樟樹國際實創高級中等學校<br />
            高中部多媒體動畫科<br />
            指導老師：徐薏筑、李靜宣<br />
            製作學生：章昭儀、杜翊寧、陳誼臻、林星廷、吳禹萱、陳佑安<br />
            版權所有：新北市樟樹國際實創高級中等學校
          </p>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  methods: {
    getPhoto(index) {
      return "/files/poker/P (" + index + ").png";
    },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
